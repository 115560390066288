<template>
  <div class="mt-5">
    <block-dashboard-wrapper title="Liste des phases">
      <template v-slot:button>
        <button
          type="button"
          class="content-wrapper__header-button"
          @click="openCreatePhaseModal()"
        >
          Ajouter une phase
        </button>
      </template>
      <block-phases-table
        @updatePhase="openEditPhaseModal($event)"
        :phases="phases"
      />
    </block-dashboard-wrapper>
    <molecule-modal-wrapper :open="isModalOpen" @modalClose="closeModal()">
      <block-phases-form
        :phase="modalPhase"
        @modalClose="closeModal()"
      ></block-phases-form>
    </molecule-modal-wrapper>
  </div>
</template>

<script setup>
import MoleculeModalWrapper from "@/components/molecules/MoleculeModalWrapper.vue";
import BlockPhasesTable from "@/components/blocks/tables/BlockPhasesTable.vue";
import BlockDashboardWrapper from "@/components/blocks/BlockDashboardWrapper.vue";
import BlockPhasesForm from "@/components/blocks/forms/BlockPhasesForm.vue";
import { computed, onBeforeMount, ref } from "vue";
import {useStore} from "vuex";
const {dispatch, state} = useStore();
// Phases 
const phases = computed( () => state.phases.phases);
onBeforeMount( async () => {
  if(!phases.value?.length){
    try {
      await dispatch('phases/getAll');
    } catch (err) {
      const {message} = err.response.data;
      console.log(message);
    }
  }
});

// Modal code
const isModalOpen = ref(false);
const closeModal = () => {
  isModalOpen.value = false;
};
// Modal phase code
const modalPhase = ref({});
// Create phase function
const openCreatePhaseModal = () => {
  isModalOpen.value = true;
  modalPhase.value = {};
};
// Edit phase function
const openEditPhaseModal = (phase) => {
  const {createdAt, updateAt,...phaseToUpdate} = phase;
  modalPhase.value = phaseToUpdate;
  isModalOpen.value = true;
};
</script>

<style lang="scss" scoped>
</style>
