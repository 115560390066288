<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 lg:pl-10">
        <div
          class="shadow border-b border-gray-200 sm:rounded-lg"
        >
          <table class="phases-table">
            <thead class="phases-table__header">
              <tr>
                <th scope="col" class="phases-table__header-col">Titre</th>
                <th scope="col" class="phases-table__header-col">Date de début</th>
                <th scope="col" class="phases-table__header-col">Date de fin</th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody class="phases-table__body">
              <tr
                :class="`phases-table__row ${isCurrentPhase(phase) ? 'phases-table__row--current':''}`"
                v-for="phase in phases"
                :key="phase.id"
              >
                <td class="phases-table__row-cel">
                  <div class="text-sm text-gray-900">
                    <span class="phases-table__title">{{phase.title}}</span>
                  </div>
                </td>
                <td class="phases-table__row-cel phases-table__row-cel--fixed">
                  <div class="text-sm text-gray-900">
                    <span>{{moment(phase.startDate).format("ddd MMM DD, YYYY")}}</span>
                  </div>
                </td>
                <td class="phases-table__row-cel">
                  <div class="text-sm text-gray-900">
                    <span>{{moment(phase.endDate).format("ddd MMM DD, YYYY")}}</span>
                  </div>
                </td>
                <td class="phases-table__row-cel text-right text-sm font-medium">
                  <a @click.prevent="$emit('updatePhase',phase)" href="#" class="text-primary hover:text-primary/90 mr-2"
                    >Modifier</a
                  >
                  <a @click.prevent="deletePhase(phase.id)" class="cursor-pointer text-red-500 hover:text-red-400"
                    >Supprimer</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- <block-confirmation-modal
  :open="isConfirmationModalOpen"
   @modalClose="isConfirmationModalOpen = false" 
   :content="confirmationModalContent"/> -->
</template>

<script setup>
import { computed, toRefs, ref, inject } from "vue";
import BlockConfirmationModal from "@/components/blocks/BlockConfirmationModal.vue";
import moment from 'moment';
moment.locale("fr");
import {useStore} from "vuex";
const {dispatch} = useStore();
const props = defineProps({
  phases:{
    required:true
  }
});
const {phases} = toRefs(props);
const isConfirmationModalOpen = ref(false);
const confirmDeletion = ref(true);
const confirmationModalContent = ref({
  title:'Supprimer la phase',
  description: 'Êtes-vous sûr de vouloir supprimer la phase ?',
  primaryCta:'Supprimer'
});
const isCurrentPhase = (phase) => {
  return moment(phase.startDate) <= moment() && moment(phase.endDate) > moment() ? true:false;
};
const deletePhase = async (phaseId) => {
  try {
    const deletedPhase = await dispatch('phases/delete', phaseId);
  } catch (err) {
    const {message} = err.response.data;
    console.log(message);
  }
}
</script>
<style lang="scss" scoped>
.phases-table {
  @apply min-w-full divide-y divide-gray-200 table-fixed;
  &__header {
    @apply bg-gray-50;
    &-col {
      @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
    }
  }
  &__body {
    @apply bg-white divide-y divide-gray-200;
  }
  &__row {
    &-cel {
      @apply px-6 py-4 whitespace-nowrap;
      &--fixed{
        @apply min-w-[250px] 
        whitespace-normal;
      }
      &-status {
        @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full;
        &--active {
          @apply bg-green-100 text-green-600;
        }
        &--blocked {
          @apply bg-red-100 text-red-600;
        }
        &--pending {
          @apply bg-gray-100 text-gray-600;
        }
      }
    }
    &--current{
      @apply bg-primary/30 relative;
      td:first-child{
        &:before{
          content:'Actuelle';
          text-orientation: mixed;
          writing-mode: vertical-lr;
          @apply absolute -left-5 text-xs top-0 text-center h-full bg-primary text-white px-0.5 -rotate-180;
        }
      }
    }
  }
}
</style>
